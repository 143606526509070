var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.canWrite('spinners'))?_c('router-link',{staticClass:"btn btn-link my-2",attrs:{"to":{
		name: 'projects-editor-uuid-cuuid-tests-reports-new',
		params: {
			uuid: _vm.$route.params.uuid,
		   cuuid: _vm.$route.params.cuuid
		}
	}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" NEW REPORT ")]):_vm._e(),_c('div',{staticClass:"my-2"},[_c('table',{staticClass:"table table-hover"},[_vm._m(0),_c('tbody',_vm._l((_vm.reports),function(report){return _c('router-link',{key:report.Id,staticClass:"cursor-pointer",attrs:{"to":{
				name: 'projects-editor-uuid-cuuid-tests-reports-report_id',
				params: {
					report_id: report.Id
				}
			},"tag":"tr"}},[_c('td',{domProps:{"textContent":_vm._s(report.Id)}}),_c('td',{directives:[{name:"rel-date",rawName:"v-rel-date",value:(report.CreatedAt),expression:"report.CreatedAt"}]}),_c('td',{domProps:{"textContent":_vm._s(report.Version)}}),_c('td',[_c('spinner-status-label',{attrs:{"status":report.Status}}),(report.Notes)?_c('div',{staticClass:"mt-1 p-2 bg-light text-nowrap text-truncate",staticStyle:{"max-width":"200px"},attrs:{"title":report.Notes},domProps:{"textContent":_vm._s(report.Notes)}}):_vm._e()],1),_c('td',{domProps:{"textContent":_vm._s(report.Type)}}),_c('td',{domProps:{"textContent":_vm._s(report.Bet)}}),_c('td',_vm._l((report.Paylines),function(payline){return _c('div',{staticClass:"badge text-uppercase"},[_vm._v(_vm._s(payline))])}),0),_c('td',[_vm._l((report.Spins),function(spin){return (report.Type === 'standard')?_c('div',{staticClass:"badge text-uppercase"},[_vm._v(_vm._s(_vm._f("numeral")(spin,'Oa'))+" ")]):_vm._e()}),(report.Type === 'stress')?_c('div',{staticClass:"badge text-uppercase"},[_vm._v(_vm._s(_vm._f("numeral")(report.Spins[0],'Oa'))+" x "+_vm._s(_vm._f("numeral")(report.Spins[1],'Oa'))+" spins ")]):_vm._e()],2),_c('td',[(report.Summary.balance.total_bet)?[_vm._v(" "+_vm._s(_vm._f("numeral")(report.Summary.balance.total_win / report.Summary.balance.total_bet,'0.00%'))+" ")]:_vm._e(),_c('div',{staticClass:"text-small"},[_vm._v(_vm._s(report.NbSpinnerTests - report.NbSpinnerTestsDone)+" tests left")])],2),_c('td',[_c('div',{staticClass:"d-flex flex-nowrap"},[_c('report-excel-download-button',{staticClass:"btn btn-link btn-primary",attrs:{"report":report}}),(_vm.canWrite('spinners'))?_c('button',{staticClass:"btn btn-link btn-danger",attrs:{"disabled":_vm.deleting},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.deleteReport(report.Id)}}},[_c('i',{staticClass:"fa fa-trash"})]):_vm._e()],1)])])}),1)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('td',[_vm._v("ID")]),_c('td',[_vm._v("Date/time")]),_c('td',[_vm._v("Version")]),_c('td',[_vm._v("Status")]),_c('td',[_vm._v("Type")]),_c('td',[_vm._v("Bet")]),_c('td',[_vm._v("Lines")]),_c('td',[_vm._v("Spins")]),_c('td',[_vm._v("RTP")]),_c('td')])])
}]

export { render, staticRenderFns }